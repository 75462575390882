<template>
  <BaseModalSmall
      @close="close"
      v-bind:isValidated="true"
      @confirm="next"
      :noSlide="true"
  >
    <template v-slot:header>Warning</template>
    <template v-slot:body>
      <div>
        <p>
          It looks like you are excluding {{ excludedChildrenMessage }}. Be aware that where a child is excluded they
          may be able to challenge your Will. For more information you can request an appointment at the end of the
          process.
        </p>
        <a href="#" @click="close" class="link primary">Back</a>
      </div>
    </template>
    <template v-slot:button>Continue</template>
  </BaseModalSmall>
</template>

<script>
import { excludedChildrenHelpers } from '@/common/mixins/excludedChildrenHelpers'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'ExcludeChildrenWarning',
  props: ['next'],
  mixins: [excludedChildrenHelpers, willStringHelpers],
  components: {
    BaseModalSmall
  },
  computed: {
    excludedChildrenMessage () {
      return this.listPeopleGroupsCharities(
          this.missingChildren,
          this.missingFutureChildren,
          [],
          ' and ',
          true,
          false,
          true
      )
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
