export const excludedChildrenHelpers = {
  computed: {
    children() {
      return this.$store.getters.people.filter((child) => child.child)
    },

    excludedChildren() {
      return this.$store.getters.people.filter(
        (person) => person.child && !person.distribution_primary
      )
    },
    missingChildren() {
      let sonsMissing = []
      let daughtersMissing = []
      if (!this.sonsGroup && this.sonsPeople) {
        sonsMissing = this.excludedChildren.filter(
          (child) => child.gender === 'Male'
        )
      }
      if (!this.daughtersGroup && this.daughtersPeople) {
        daughtersMissing = this.excludedChildren.filter(
          (child) => child.gender === 'Female'
        )
      }
      return sonsMissing.concat(daughtersMissing)
    },
    missingFutureChildren() {
      if (this.$store.getters.personalDetails.children === 'expecting') {
        let group = this.$store.getters.groups.filter(
          (group) => group.group_name === 'Future Children'
        )
        if (!group.length) return [{ group_name: 'Future Children' }]
        return []
      }
      return []
    },
    sonsGroup() {
      return this.$store.getters.groups.filter((group) =>
        group.group_name.includes('son')
      ).length
    },
    daughtersGroup() {
      return this.$store.getters.groups.filter((group) =>
        group.group_name.includes('daughter')
      ).length
    },
    sonsPeople() {
      return this.children.filter((child) => child.gender === 'Male').length
    },
    daughtersPeople() {
      return this.children.filter((child) => child.gender === 'Female').length
    },
    isAre() {
      if (this.missingChildren.length === 1) return ' is'
      else return ' are'
    },
  }
}