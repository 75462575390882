<template>
  <NavQuestion
      :heading="heading"
      :number="number"
      :subHeading="subHeading"
      :questionOptions="true"
  >
    <template v-slot:contents>
      <DistributionInputOptions
          :children="children.length"
          @addChildren="addChildren"
          @addPerson="addPerson"
          @addGroup="addGroup"
          @addCharity="addCharity"
      ></DistributionInputOptions>
      <DistributionTable
          v-show="people.length || groups.length || charities.length"
          :people="people"
          :groups="groups"
          :charities="charities"
          :percentage="percentage"
          :remainingPercentage="remainingPercentage"
          :primary="true"
          :backup="false"
          @incrementPerson="incrementPerson"
          @decrementPerson="decrementPerson"
          @valuePerson="setValuePerson"
          @removePerson="removePerson"
          @incrementGroup="incrementGroup"
          @decrementGroup="decrementGroup"
          @valueGroup="setValueGroup"
          @removeGroup="removeGroup"
          @incrementCharity="incrementCharity"
          @decrementCharity="decrementCharity"
          @valueCharity="setValueCharity"
          @removeCharity="removeCharity"
          @splitEqually="splitEqually"
      ></DistributionTable>
      <DistributionPersonModal
          v-show="show.personModal"
          :show="show.personModal"
          :peopleOptionList="peopleOptionList"
          :primary="true"
          :backup="false"
          @close="show.personModal = false"
      ></DistributionPersonModal>
      <DistributionGroupModal
          v-show="show.groupModal"
          :show="show.groupModal"
          :primary="true"
          :backup="false"
          @close="show.groupModal = false"
      ></DistributionGroupModal>
      <DistributionCharityModal
          v-show="show.charityModal"
          :show="show.charityModal"
          :primary="true"
          :backup="false"
          @close="show.charityModal = false"
      ></DistributionCharityModal>
      <DeleteModal
          v-show="show.deleteModal"
          :show="show.deleteModal"
          @close="closeDeleteModal"
          @confirm="deleteBeneficiary"
          v-bind:bodyText="deleteText"
      ></DeleteModal>
      <ExcludeChildrenWarning
          v-if="show.excludeChildrenWarning"
          :next="nextFunction"
          @close="show.excludeChildrenWarning = false"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
          v-bind:validated="isValidated"
          v-bind:forwardText="'Next'"
          v-bind:forwardLink="forwardTo"
          v-bind:backLink="backTo"
          v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import DistributionTable from './Tables/Table'
import DistributionInputOptions from './Tables/InputOptions'
import DeleteModal from '@/common/components/DeleteModal'
import DistributionPersonModal from './Modals/Person'
import DistributionGroupModal from './Modals/Group'
import DistributionCharityModal from './Modals/Charity'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { http } from '@/services'
import ExcludeChildrenWarning from '@/views/pages/DistributionPage/Questions/Modals/ExcludeChildrenWarning'

export default {
  name: 'DistributionSplit',
  mixins: [willStringHelpers],
  components: {
    ExcludeChildrenWarning,
    NavQuestion,
    NavQuestionButtons,
    DistributionTable,
    DistributionInputOptions,
    DistributionPersonModal,
    DistributionGroupModal,
    DistributionCharityModal,
    DeleteModal
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('savePeople')
    this.$store.dispatch('saveGroups')
    this.$store.dispatch('saveCharities')
    console.log(this.forwardTo)
    if (this.childrenMissing && to.path === this.forwardTo) {
      this.show.excludeChildrenWarning = true
      next(false)
      this.nextFunction = next
    } else {
      next()
    }

  },
  computed: {
    isValidated () {
      var result = true
      this.people.forEach((person) => {
        if (
            Number(person.distribution_primary_percentage) === 0 ||
            isNaN(Number(person.distribution_primary_percentage))
        ) {
          result = false
        }
      })
      this.groups.forEach((group) => {
        if (
            Number(group.distribution_percentage) === 0 ||
            isNaN(Number(group.distribution_percentage))
        ) {
          result = false
        }
      })
      this.charities.forEach((charity) => {
        if (
            Number(charity.distribution_percentage) === 0 ||
            isNaN(Number(charity.distribution_percentage))
        ) {
          result = false
        }
      })
      if (this.percentage !== 100) result = false
      return result
    },
    heading () {
      if (this.partner && this.partner.distribution_sole_primary) {
        return (
            'If ' +
            this.partner.full_name +
            ' passes away before you how would you like your estate to be distributed?'
        )
      }
      return 'How would you like your estate to be distributed?'
    },
    number () {
      if (this.partner) return '2'
      return '1'
    },
    partner () {
      return this.$store.getters.partner
    },
    children () {
      return this.$store.getters.children
    },
    personalDetails () {
      return this.$store.getters.personalDetails
    },
    subHeading () {
      return (
          'You can add as many beneficiaries as you wish, then assign a percentage to each person, group of people or charity. ' +
          'If you want to leave amounts of money or specific items this can be done separately in the gifts section. If you add a group ' +
          'of people such as grandchildren or nieces the percentage will be split between them.'
      )
    },
    forwardTo () {
      if (this.people && this.people.length) {
        return `/distribution/primary_person/${this.people[0].full_name}/${this.people[0].id}`
      } else if (this.groups && this.groups.length) {
        return `/distribution/primary_group/${this.groups[0].group_name}/${this.groups[0].id}`
      }
      if (this.charities.length) return '/distribution/minors'
      return '/distribution/all_fail'
    },
    backTo () {
      if (this.partner) return '/distribution/partner'
      return '/home'
    },
    details () {
      return this.$store.getters.details
    },
    people () {
      return this.$store.getters.peopleDistributionPrimary
    },
    groups () {
      return this.$store.getters.groupsDistributionPrimary
    },
    charities () {
      return this.$store.getters.charities.filter(
          (charity) => charity.distribution_primary
      )
    },
    peopleOptionList () {
      return this.$store.getters.people.filter(
          (person) =>
              !person.distribution_sole_primary && !person.distribution_primary
      )
    },
    deleteText () {
      if (this.selected.person) {
        return (
            'Remove ' + this.selected.person.full_name + ' from you distribution?'
        )
      } else if (this.selected.group) {
        return (
            'Remove ' +
            this.selected.group.group_name +
            ' from your distribution?'
        )
      } else if (this.selected.charity) {
        return (
            'Remove ' +
            this.selected.charity.charity_name +
            ' from your distribution?'
        )
      }
      return ''
    },
    percentage () {
      var total = 0
      this.people.forEach((person) => {
        total += Number(person.distribution_primary_percentage)
      })
      this.groups.forEach((person) => {
        total += Number(person.distribution_percentage)
      })
      this.charities.forEach((charity) => {
        total += Number(charity.distribution_percentage)
      })
      if (total % 2 !== 0) return Number(total.toFixed(2))
      return Number(total)
    },
    remainingPercentage () {
      if (this.percentage % 2 !== 0) {
        return Number(100 - this.percentage).toFixed(2)
      }
      return 100 - this.percentage
    },
    totalBeneficiaries () {
      var count = 0
      count += this.people.length + this.groups.length + this.charities.length
      return count
    },
    fixedNumber () {
      if (100 % (100 / this.totalBeneficiaries) === 0) return 0
      return 2
    },
    excludedChildren () {
      return this.$store.getters.people.filter(
          (person) => person.child && !person.distribution_primary
      )
    },
    excludedFutureChildren () {
      let expecting = this.personalDetails.children === 'expecting'
      let group = this.$store.getters.groups.filter(
          (group) => group.group_name === 'Future Children'
      )
      return expecting && !group.length
    },
    childrenMissing () {
      if (this.children &&
          (this.children.length !==
              this.children.filter(
                  (child) => child.distribution_primary
              ).length)
      ) {
        // check if children are named all together in a group
        if (
            this.$store.getters.groups.filter(
                (group) => group.group_name === 'children'
            ).length > 0
        ) {
          return false
        }
        if (this.excludedFutureChildren) return true
        // check if children are name severally in a group

        const sonsGroup = this.$store.getters.groups.filter((group) =>
            group.group_name.includes('son')
        ).length

        const daughtersGroup = this.$store.getters.groups.filter((group) =>
            group.group_name.includes('daughter')
        ).length

        const sonsPeople = this.children.filter(
            (child) => child.gender === 'Male'
        ).length

        const daughtersPeople = this.children.filter(
            (child) => child.gender === 'Female'
        ).length

        let sonsMissing = []
        let daughtersMissing = []
        if (!sonsGroup && sonsPeople) {
          sonsMissing = this.excludedChildren.filter(
              (child) => child.gender === 'Male'
          )
        }
        if (!daughtersGroup && daughtersPeople) {
          daughtersMissing = this.excludedChildren.filter(
              (child) => child.gender === 'Female'
          )
        }
        return !!sonsMissing.concat(daughtersMissing).length
      }
      return !!this.excludedFutureChildren
    },
  },
  data () {
    return {
      show: {
        personModal: false,
        groupModal: false,
        charityModal: false,
        deleteModal: false,
        intro: true,
        excludeChildrenWarning: false
      },
      selected: {
        person: null,
        group: null,
        charity: null
      },
      nextFunction: null
    }
  },
  methods: {
    addChildren () {
      let futureChildrenGroups = this.groups.filter(
          (group) => group.group_name === 'Future Children'
      )
      if (this.children.length) {
        this.children.forEach((person) => {
          let personCopy = Object.assign({}, person)
          personCopy.distribution_primary = true
          this.$store.commit('personAdd', personCopy)
        })
      }
      // add expecting children group
      if (
          this.$store.getters.personalDetails.children === 'expecting' &&
          futureChildrenGroups.length < 1
      ) {
        http
            .post('/wills/api/group', {
              group_name: 'Future Children',
              distribution_primary: true,
              distribution_backup: false
            })
            .then((response) => {
              this.$store.commit('groupAdd', response.data)
              this.close()
              this.$notification.success('Group Added')
            })
            .catch((error) => {
              console.log(error)
            })
      }
    },
    splitEqually () {
      var calc = 100 / this.totalBeneficiaries
      var share = calc.toFixed(this.fixedNumber)

      var i = 0
      var peopleCopy = []

      this.people.forEach((person) => {
        peopleCopy.push(Object.assign({}, person))
      })

      peopleCopy.forEach((person) => {
        i += 1
        var thisShare = share
        if (i === this.totalBeneficiaries) {
          thisShare = (100 - share * (this.totalBeneficiaries - 1)).toFixed(
              this.fixedNumber
          )
        }
        person.distribution_primary_percentage = String(thisShare)
        this.$store.commit('personAdd', person)
      })
      var groupsCopy = []

      this.groups.forEach((group) => {
        groupsCopy.push(Object.assign({}, group))
      })

      groupsCopy.forEach((group) => {
        i += 1
        var thisShare = share
        if (i === this.totalBeneficiaries) {
          thisShare = (100 - share * (this.totalBeneficiaries - 1)).toFixed(
              this.fixedNumber
          )
        }
        group.distribution_percentage = String(thisShare)
        this.$store.commit('groupAdd', group)
      })

      var charitiesCopy = []

      this.charities.forEach((charity) => {
        charitiesCopy.push(Object.assign({}, charity))
      })

      charitiesCopy.forEach((charity) => {
        i += 1
        var thisShare = share
        if (i === this.totalBeneficiaries) {
          thisShare = (100 - share * (this.totalBeneficiaries - 1)).toFixed(
              this.fixedNumber
          )
        }
        charity.distribution_percentage = String(thisShare)
        this.$store.commit('charityAdd', charity)
      })
    },
    addPerson () {
      this.show.personModal = true
    },
    addGroup () {
      this.show.groupModal = true
    },
    addCharity () {
      this.show.charityModal = true
    },
    clearSelected () {
      this.selected.person = null
      this.selected.group = null
      this.selected.charity = null
    },
    closeDeleteModal () {
      this.clearSelected()
      this.show.deleteModal = false
    },
    deleteBeneficiary () {
      if (this.selected.person) {
        let personCopy = Object.assign({}, this.selected.person)
        personCopy.distribution_primary = false
        personCopy.distribution_primary_percentage = '0'
        personCopy.distribution_backup_person = null
        personCopy.distribution_backup_group = ''
        personCopy.distribution_backup_children = false
        personCopy.distribution_backup_other_beneficiaries = false
        personCopy.distribution_backup_children_no = false
        this.$store.commit('personAdd', personCopy)
        this.$notification.success('Person removed')
      } else if (this.selected.group) {
        this.$store.dispatch('deleteGroupFromServer', this.selected.group.id)
        this.$notification.success('Group removed')
      } else if (this.selected.charity) {
        this.$store.dispatch(
            'deleteCharityFromServer',
            this.selected.charity.id
        )
        this.$notification.success('Charity removed')
      }
      this.closeDeleteModal()
    },
    removePerson (value) {
      this.selected.person = value
      this.show.deleteModal = true
    },
    removeGroup (value) {
      this.selected.group = value
      this.show.deleteModal = true
    },
    removeCharity (value) {
      this.selected.charity = value
      this.show.deleteModal = true
    },
    incrementPerson (person) {
      var personCopy = Object.assign({}, person)

      if (Number(personCopy.distribution_primary_percentage) + 10 <= 100) {
        personCopy.distribution_primary_percentage = Number(
            Number(personCopy.distribution_primary_percentage) + 10
        ).toFixed(this.fixedNumber)

        this.$store.commit('personAdd', personCopy)
      }
    },
    decrementPerson (person) {
      var personCopy = Object.assign({}, person)

      if (Number(personCopy.distribution_primary_percentage) - 10 >= 0) {
        personCopy.distribution_primary_percentage = Number(
            Number(personCopy.distribution_primary_percentage) - 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('personAdd', personCopy)
      }
    },
    setValuePerson (person, value) {
      var personCopy = Object.assign({}, person)
      if (value && value.includes('.00')) {
        personCopy.distribution_primary_percentage = value.replace('.00', '')
      } else if (value && value[0] === '0') {
        personCopy.distribution_primary_percentage = value.slice(1)
      } else {
        personCopy.distribution_primary_percentage = value
      }
      this.$store.commit('personAdd', personCopy)
    },
    incrementGroup (group) {
      var groupCopy = Object.assign({}, group)
      if (Number(groupCopy.distribution_percentage) + 10 <= 100) {
        groupCopy.distribution_percentage = Number(
            Number(group.distribution_percentage) + 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('groupAdd', groupCopy)
      }
    },
    decrementGroup (group) {
      var groupCopy = Object.assign({}, group)
      if (Number(groupCopy.distribution_percentage) - 10 >= 0) {
        groupCopy.distribution_percentage = Number(
            Number(groupCopy.distribution_percentage) - 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('groupAdd', groupCopy)
      }
    },
    setValueGroup (group, value) {
      if (value && value.includes('.00')) {
        group.distribution_percentage = value.replace('.00', '')
      } else {
        group.distribution_percentage = value
      }
      this.$store.commit('groupAdd', group)
    },
    incrementCharity (charity) {
      var charityCopy = Object.assign({}, charity)
      if (Number(charityCopy.distribution_percentage) + 10 <= 100) {
        charityCopy.distribution_percentage = Number(
            Number(charityCopy.distribution_percentage) + 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('charityAdd', charityCopy)
      }
    },
    decrementCharity (charity) {
      var charityCopy = Object.assign({}, charity)
      if (Number(charityCopy.distribution_percentage) - 10 >= 0) {
        charityCopy.distribution_percentage = Number(
            Number(charityCopy.distribution_percentage) - 10
        ).toFixed(this.fixedNumber)
        this.$store.commit('charityAdd', charityCopy)
      }
    },
    setValueCharity (charity, value) {
      if (value && value.includes('.00')) {
        charity.distribution_percentage = value.replace('.00', '')
      } else {
        charity.distribution_percentage = value
      }
      this.$store.commit('charityAdd', charity)
    }
  }
}
</script>
